@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-xl text-white text-center;
  }

  h2 {
    @apply text-2xl text-center my-2 font-bold;
  }
}

:focus {
  outline: none;
}

html {
  scroll-behavior: smooth;
}

.pointsTableRow td {
  width: 33%;
}
